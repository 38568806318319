import React from "react";

const Tv = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center bg-white">
      <div className="w-4/5 lg:w-1/2 flex flex-col justify-start items-start py-12">
        <p className="text-3xl font-thin text-gray-800 mb-6">
          TETANUS, DIPHTHERIA (Td) or TETANUS, DIPHTHERIA, PERTUSSIS (Tdap)
          VACCINE WHAT YOU NEED TO KNOW
        </p>

        <p className="text-xl text-gray-800 mb-2">1. Why get vaccinated?</p>
        <p className="text-md text-gray-600 mb-2">
          Children 6 years of age and younger are routinely vaccinated against
          tetanus, diphtheria and pertussis. But older children, adolescents,
          and adults need protection from these diseases too. Td (Tetanus,
          Diphtheria) and Tdap (Tetanus, Diphthe­ ria, Pertussis) vaccines
          provide that protection.
        </p>
        <p className="text-md text-gray-600 mb-2">
          TETANUS (Lockjaw) causes painful muscle spasms, usually all over the
          body.
        </p>
        <p className="text-md text-gray-600 mb-2">
          It can lead to tightening of the jaw muscles so the victim cannot open
          his mouth or swallow. Tetanus kills about 1 out of 5 people who are
          infected.
        </p>
        <p className="text-md text-gray-600 mb-2">
          DIPHTHERIA causes a thick covering in the back of the throat. • It can
          lead to breathing problems, paralysis, heart failure, and even death.
        </p>
        <p className="text-md text-gray-600 mb-2">
          PERTUSSIS (Whooping Cough) causes severe coughing spells, vomiting,
          and disturbed sleep.
        </p>
        <p className="text-md text-gray-600 mb-2">
          It can lead to weight loss, incontinence, rib fractures and passing
          out from violent coughing. Up to 2 in 100 adoles- cents and 5 in 100
          adults with pertussis are hospitalized or have complications,
          including pneumonia.
        </p>
        <p className="text-md text-gray-600 mb-2">
          These three diseases are all caused by bacteria. Diphtheria and
          pertussis are spread from person to person. Tetanus enters the body
          through cuts, scratches, or wounds.
        </p>
        <p className="text-md text-gray-600 mb-2">
          The United States averaged more than 1,300 cases of tetanus and
          175,000 cases of diphtheria each year before vaccines. Since vaccines
          have been available, tetanus cases have fallen by over 96% and
          diphtheria cases by over 99.9%.
        </p>
        <p className="text-md text-gray-600 mb-12">
          Before 2005, only children younger than than 7 years of age could get
          pertussis vaccine. In 2004 there were more than 8,000 cases of
          pertussis in the U.S. among adolescents and more than 7,000 cases
          among adults.
        </p>

        <p className="text-xl text-gray-800 mb-2">2. Td and Tdap vaccines</p>
        <p className="text-md text-gray-600 mb-2">
          Td vaccine has been used for many years. It protects against tetanus
          and diphtheria.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Tdap was licensed in 2005. It is the first vaccine for ado- lescents
          and adults that protects against all three diseases.
        </p>
        <p className="text-md text-gray-600 mb-12">
          These vaccines can be used in three ways: 1) as catch-up for people
          who did not get all their doses of DTaP or DTP when they were
          children, 2) as a booster dose every 10 years, and 3) for protection
          against tetanus infection after a wound.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          3. Which vaccine, and when?
        </p>
        <p className="text-md text-gray-600 mb-2">
          Routine: Adolescents 11 through 18
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            A dose of Tdap is recommended for adolescents who got DTaP or DTP as
            children and have not yet gotten a booster dose of Td. The preferred
            age is 11-12.
          </li>
          <li>
            Adolescents who have already gotten a booster dose of Td are
            encouraged to get a dose of Tdap as well, for protection against
            pertussis. Waiting at least 5 years between Td and Tdap is
            encouraged, but not required.
          </li>
          <li>
            Adolescents who did not get all their scheduled doses of DTaP or DTP
            as children should complete the series using a combination of Td and
            Tdap.
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          Routine: Adults 19 and Older
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            All adults should get a booster dose of Td every 10 years. Adults
            under 65 who have never gotten Tdap should substitute it for the
            next booster dose.
          </li>
          <li>
            Adults under 65 who expect to have close contact with an infant
            younger than 12 months of age (including women who may become
            pregnant) should get a dose of Tdap. Waiting at least 2 years since
            the last dose of Td is suggested, but not required.
          </li>
          <li>
            Healthcare workers under 65 who have direct patient contact in
            hospitals or clinics should get a dose of Tdap. A 2-year interval
            since the last Td is suggested, but not required.
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          New mothers who have never gotten Tdap should get a dose as soon as
          possible after delivery. If vaccination is needed during pregnancy, Td
          is usually preferred over Tdap.
        </p>
        <p className="text-md text-gray-600 mb-2">Protection After a Wound</p>
        <p className="text-md text-gray-600 mb-2">
          A person who gets a severe cut or burn might need a dose of Td or Tdap
          to prevent tetanus infection. Tdap may be used for people who have
          never had a dose. But Td should be used if Tdap is not available, or
          for:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            Anybody who has already had a dose of Tdap, – children 7 through 9
            years of age
          </li>
          <li>Adults 65 and older.</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          Tdap and Td may be given at the same time as other vaccines.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          4. Some people should not be vaccinated or should wait
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            Anyone who has had a life-threatening allergic reaction after a dose
            of DTP, DTaP, DT, or Td should not get Td or Tdap.
          </li>
          <li>
            Anyone who has a severe allergy to any component of a vaccine should
            not get that vaccine. Tell your provider if the person getting the
            vaccine has any severe allergies.
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          Note: At this time, Tdap is licensed for only one lifetime dose per
          person. Td is given every 10 years, and more often if needed.
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            Anyone who had a coma, or long or multiple seizures within 7 days
            after a dose of DTP or DTaP should not get Tdap, unless a cause
            other than the vaccine was found (these people can get Td).
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-2">
          Talk to your provider if the person getting either vaccine:
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-12">
          <li>Has epilepsy or another nervous system problem,</li>
          <li>
            Had severe swelling or severe pain after a previous doseof DTP,
            DTaP, DT, Td, or Tdap vaccine, or
          </li>
          <li>
            Has had Guillain Barré Syndrome (GBS).Anyone who has a moderate or
            severe illness on the day the shot is scheduled should usually wait
            until they recover before getting Tdap or Td vaccine. A person with
            a mild\ illness or low fever can usually be vaccinated.
          </li>
        </ul>

        <p className="text-xl text-gray-800 mb-2">
          5. What are the risks from Tdap and Td vaccines?
        </p>
        <p className="text-md text-gray-600 mb-2">
          With a vaccine (as with any medicine) there is always a small risk of
          a life-threatening allergic reaction or other serious problem.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Getting tetanus, diphtheria or pertussis would be much more likely to
          lead to severe problems than getting either vaccine.
        </p>
        <p className="text-md text-gray-600 mb-2">
          Problems reported after Td and Tdap vaccines are listed below.
        </p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-12">
          <li>Pain (about 3 in 4 adolescents and 2 in 3 adults)</li>
          <li>Redness or swelling (about 1 in 5)</li>
          <li>
            Mild fever of at least 100.4°F (up to about 1 in 25 adolescents and
            1 in 100 adults)
          </li>
          <li>Headache (about 4 in 10 adolescents and 3 in 10 adults)</li>
          <li>Tiredness (about 1 in 3 adolescents and 1 in 4 adults)</li>
          <li>
            Nausea, vomiting, diarrhea, stomach ache (up to 1 in 4 adolescents
            and 1 in 10 adults)
          </li>
          <li>
            Chills, body aches, sore joints, rash, swollen glands (uncommon)
          </li>
          <li>Pain (up to about 8 in 10)</li>
          <li>
            Redness or swelling (up to about 1 in 3) • Mild fever (up to about 1
            in 15)
          </li>
          <li>Headache or tiredness (uncommon)</li>
          <li>
            Pain at the injection site (about 1 in 20 adolescents and 1 in 100
            adults)
          </li>
          <li>
            Redness or swelling (up to about 1 in 16 adolescents and 1 in 25
            adults)
          </li>
          <li>
            Fever over 102°F (about 1 in 100 adolescents and 1 in 250 adults)
          </li>
          <li>Headache (1 in 300)</li>
          <li>
            Nausea, vomiting, diarrhea, stomach ache (up to 3 in 100 adolescents
            and 1 in 100 adults)
          </li>
        </ul>

        <p className="text-xl text-gray-800 mb-2">
          6. What if there is a severe reaction?
        </p>
        <p className="text-md text-gray-600 mb-2">What should I look for?</p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            Any unusual condition, such as a high fever or behavior changes.
            Signs of a severe allergic reaction can include difficulty
            breathing, hoarseness or wheezing, hives, paleness, weakness, a fast
            heart beat or dizziness.
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-2">What should I do?</p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>Call a doctor, or get the person to a doctor right away.</li>
          <li>
            Tell the doctor what happened, the date and time it happened, and
            when the vaccination was given.
          </li>
          <li>
            Ask your provider to report the reaction by filing a Vaccine Adverse
            Event Reporting System (VAERS) form. Or you can file this report
            through the VAERS website at www.vaers.hhs.gov, or by calling
            1-800-822-7967.
          </li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          VAERS does not provide medical advice.
        </p>

        <p className="text-xl text-gray-800 mb-2">
          7. The National Vaccine Injury Compensation Program
        </p>
        <p className="text-md text-gray-600 mb-2">
          A federal program exists to help pay for the care of anyone who has a
          serious reaction to a vaccine.
        </p>
        <p className="text-md text-gray-600 mb-12">
          For details about the National Vaccine Injury Compensation Program,
          call 1-800-338-2382 or visit their website at
          www.hrsa.gov/vaccinecompensation.
        </p>

        <p className="text-xl text-gray-800 mb-2">8. How can I learn more?</p>
        <ul className="text-gray-600 text-md list-inside list-disc pl-4 mb-2">
          <li>
            Ask your provider. They can give you the vaccine package insert or
            suggest other sources of information.
          </li>
          <li>Call your local or state health department.</li>
          <li>Call 1-800-232-4636 (1-800-CDC-INFO)</li>
          <li>Visit CDC’s website at www.cdc.gov/vaccines.</li>
        </ul>
        <p className="text-md text-gray-600 mb-12">
          DEPARTMENT OF HEALTH AND HUMAN SERVICES Centers for Disease Control
          and Prevention
        </p>
      </div>
    </div>
  );
};

export default Tv;
