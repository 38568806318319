import React from "react";
import Layout from "../../../components/Layout";
import PageHeader from "../../../components/PageHeader";
import Conditions from "../../../components/Conditions";
import Tv from "../../../components/resources/Tv";

const TetanusVaccination = () => {
  return (
    <Layout>
      <PageHeader text="Tetanus Vaccination" />
      <Tv />
      <Conditions />
    </Layout>
  );
};

export default TetanusVaccination;
